.blog {
    padding: 60px 20px;
    max-width: 700px;
    margin: 0 auto;
}

figure img {
    border-radius: 5px;
}

.prose {
    color: var(--text-color);
}

.prose h4 {
    color: var(--text-color);
}

.prose strong {
    color: var(--text-color);
}

.prose a {
    color: darkgray;
}

/* .title {
    padding-bottom: 2rem;
    align-items: left;
    color: black;
    width: 90%;
    text-align: left;
} */

.authdetails {
    display: flex;
    margin-bottom: 30px;
}

.authdetails img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: cover;
}

.date {
    font-size: 14px;
}

.content {
    margin-top: 60px;
    font-size: 18px;
    line-height: 1.6;
    width: 90%;
    text-align: left;
}

.prose blockquote {
    color: cadetblue;
}

figcaption {
    text-align: center;
    color: darkgray;
}

figcaption a {
    text-decoration: dashed;
    color: darkgray;
}

/* pre {
    font-size: 1rem;
    background: rgb(55, 55, 55);
    color: whitesmoke;
    display: block;
    padding: 1rem;
    margin: 2rem 0rem;
    border-radius: 5px;
} */

@media screen and (max-width: 768px) {
    .blog {
        padding: 0rem 1rem;
    }

    .content {
        width: 100%;
    }

    .content img {
        width: 100%;
    }
}