.resource-card {
    background-color: #f9f9f9;
    text-align: center;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    margin-bottom: 40px;
}

.resource-card:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
}

.resource-card i {
    color: #3498db;
    margin-bottom: 20px;
}

.resource-card .card-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 1px;
    color: #333;
}

.resource-card .card-text {
    font-size: 1em;
    line-height: 1.5em;
    color: #666;
    text-align: center;
}