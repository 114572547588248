/* .po_items_ho {
    column-width: 15em;
    -moz-column-width: 15em;
    -webkit-column-width: 15em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 4rem;
} */

/* .po_item {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    padding: 6px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
    min-height: 300px;
} */

/* .po_item img {
    max-width: 100%;
} */

img {
    width: 100%;
}

.po_item {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    padding: 6px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
}

.po_item .content {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(12 12 12 / 63%);
    z-index: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
    border: 1px solid black;
    text-align: center;
}

.po_item .content p {
    color: white;
}

/* .po_item .content {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
} */

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 25px);
    opacity: 1;
}

@media screen and (max-width: 576px){
    .po_item:hover .content {
        top: 28% !important;
        height: calc(100% - 40px);
        width: calc(100% - 15px);
    }

    .po_item .content p {
        font-size: 13px !important;
    }

    .po_item .content a {
        font-size: 13px !important;
    }

}

@media screen and (min-width: 600px) and (max-width: 768px){
    .po_item:hover .content {
        top: 28% !important;
        height: calc(100% - 40px);
        width: calc(100% - 25px);
    }

    .po_item .content p {
        font-size: 15px !important;
    }

    .po_item .content a {
        font-size: 13px !important;
    }
}

.po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .content a:hover {
    text-decoration: none;
}