.stick_spotify {
    position: fixed;
    right: 0px;
    top: 50%;
    margin-top: -100px;
    width: 50px;
    height: 150px;
    transition: all 0.5s ease-in-out;
  }
  
  .stick_spotify:hover {
    width: 300px;
  }
  
  .stick_spotify .icon {
    height: 100%;
    font-size: 40px;
    color: #1DB954;
  }

  @media screen and (max-width: 576px) {
    .stick_spotify .icon {
      font-size: 35px;
    }
    .stick_spotify {
      top: 75%;
    }
  }
  
  .stick_spotify .player {
    display: none;
    height: 100%;
  }
  
  .stick_spotify:hover .player {
    display: block;
  }
  