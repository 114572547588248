:root {
    --bg-color: #0c0c0c;
    --primary-color: #0d0d0d;
    --secondary-color: #fff;
    --text-color: #fff;
    --text-color-2: #fff;
    --overlay-color: rgb(12 12 12 / 63%);
}

[data-theme="light"] {
    --bg-color: #ffffff;
    --primary-color: #ffffff;
    --secondary-color: #000;
    --text-color: #000;
    --text-color-2: #000;
    --overlay-color: rgb(255 255 255 / 70%);
}

html{
    position:relative; 
    min-height: 100%;
}

html,body{
    margin:0;
    padding:0;
    overflow-x: hidden;
}

.pageContentWrapper{
    margin-bottom:100px;/* Height of footer*/
} 

.footer{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height:100px;
}

body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: var(--bg-color);
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 60px;
    border-left: 10px solid var(--primary-color);
    border-right: 10px solid var(--primary-color);
}

ul {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Marcellus;
}

.link {
    color: #3e4041;
    text-decoration: underline;
}

a {
    color: var(--text-color);
}

a:hover {
    color: var(--text-color);
}

p {
    word-break: break-word;
    hyphens: auto;
}

.ovhidden {
    overflow: hidden;
}

.text_2,
.text_2:hover {
    color: var(--text-color-2);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor__dot div {
    z-index: 999999 !important;
}

.cursor__dot div:last-child {
    filter: invert(1);
    background-color: var(--primary-color) !important;
}

.cursor__dot div:first-child {
    filter: invert(1);
    background-color: var(--overlay-color) !important;
}

.color_pr {
    color: var(--primary-color) !important;
}

.color_sec {
    color: var(--secondary-color);
}

.buttons {
    background: #fff!important;
    color: #333131!important;
}

.p-l {
    padding-left: max(100px,(122vw - 1600px)/2) !important;
}

@media screen and (max-width: 1599px) {
    .p-l {
        padding-left: max(100px,(127vw - 1599px)/2) !important;
    }
}

@media screen and (max-width: 1024px) {
    .p-l {
      padding-left: max(30px, (100vw - 1024px) / 2) !important;
    }

    .m-w-contact {
        max-width: 100% !important;
    }

    .brand-img {
        width: 80% !important;
        height: 100px !important;
    }
} 

@media screen and (max-width: 576px) {
    .p-l {
      padding-left: max(10px, (100vw - 600px) / 2) !important;
    }

    .brand-img {
        width: 85% !important;
        height: 70px !important;
    }

    .padding-lg {
        padding-right: 0 !important;
    }

    .m-w-contact {
        max-width: 100% !important;
    }
}

.m-w-contact {
    max-width: 80%;
}

.brand-img {
    width: 95%;
    height: 85px;
    cursor: disabled;
}

.padding-lg {
    padding-right: 10rem;
}

.c-i-b {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .c-i-b {
        width: 20px;
        height: 20px;
    }
}

.portfolio-img {
    width: 100%;
    height: 350px;
}

.blog-img {
    width: 100% !important;
    height: 250px !important;
}

@media screen and (max-width: 1024px) {
    .portfolio-img {
        height: 250px !important;
    }

    .blog-img {
        height: 200px !important;
    }
}

@media screen and (max-width: 576px) {
    .portfolio-img {
        height: 250px !important;
    }

    .blog-img {
        height: 250px !important;
    }
}

.hide {
    opacity:0;
    left:-100%;
}
.show {
    opacity:1;
    left:0;
}

.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }

  .btn-primary{
    background-color: var(--primary-color) !important;
    border-color: var(--secondary-color) !important;
}

.btn-primary:hover {
    background-color: gray !important;
    border-color: var(--secondary-color) !important;
}

.btn {
    border-radius: 0 !important;
}

.modal-header {
    border-bottom: none !important
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #ced4da !important;
    border-radius: 0 !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--bg-color) !important;
    background-clip: padding-box;
    border: none !important;
    border-radius: 0 !important;
    outline: 0;
}

.styles_scroll-to-top__2A70v {
    background-color: white;
    right: 11px !important;
    bottom: 40px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 100px !important;
    width: 35px !important;
    height: 35px !important;
    transition: opacity 1s ease-in-out;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
    outline: none;
}

.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (max-width: 576px) {
    .form-control {
        width: 65% !important;
    }
  }
  